import '@scss/main.scss'

const $ = require('jquery')
window.jQuery = $
import Swiper from 'swiper'
import Splitting from 'splitting'
import emergence from 'emergence.js'
import L from 'leaflet'
import CookiesEuBanner from 'cookies-eu-banner'
require('@fancyapps/fancybox')

import Formino from './vendor/Formino'

document.addEventListener("DOMContentLoaded", function() {

    // Detect IE
    if(detectIE()) {
        document.querySelector('body').classList.add('o-body--ie')
        if(typeof startGTM !== 'undefined') {
            startGTM()
        }
    } else {
        // Le plugin de cookie génère une erreur sur IE10
        new CookiesEuBanner(function(event){
            if(typeof startGTM !== 'undefined') {
                startGTM()
            }
        })
    }

    // Handle burger menu
    document.querySelector('.a-burger').addEventListener('click', e => {
        e.preventDefault()
        document.querySelector('.o-body').classList.add('o-body--menu-on')
    })
    document.querySelector('.m-menu__background').addEventListener('click', () => {
        document.querySelector('.o-body').classList.remove('o-body--menu-on')
    })

    // Set Min Main Height
    function setMainHeight() {
        const headerHeight = document.querySelector('.o-header').offsetHeight
        const mainHeight = document.querySelector('.o-main').offsetHeight
        const footerHeight = document.querySelector('.o-footer').offsetHeight
        const contentHeight = headerHeight + mainHeight + footerHeight
        const windowHeight = window.innerHeight

        if(windowHeight > contentHeight) {
            const requiredHeight = windowHeight - contentHeight
            document.querySelector('.o-main').style.marginBottom = `${requiredHeight}px`
        } else {
            document.querySelector('.o-main').style.marginBottom = '0px'
        }
    }
    setMainHeight()

    // Splitting 
    Splitting({
        target: '.a-heading--char',
        by: 'chars'
    })
    Splitting({
        target: '.a-heading--word',
        by: 'words'
    })

    // Emergence
    emergence.init({
        reset: false
    })

    // Init slider
    const slider = document.querySelector('.m-slider')
    if(slider) {
        // Content slider
        const contentSwiper = new Swiper('.m-slider__content-container', {
            slidesPerView: 'auto',
            autoHeight: true,
            speed: 700,
            grabCursor: true,
            parallax: true,
            init: false,
            navigation: {
                nextEl: slider.querySelector('.m-slider__arrow--next'),
                prevEl: slider.querySelector('.m-slider__arrow--prev'),
            },
            pagination: {
                el: '.m-slider__pagination',
                type: 'fraction',
            },
            scrollbar: {
                el: slider.querySelector('.swiper-scrollbar')
            },
            on: {
                slideChange: function() {
                    // Handle tab change
                    if(slider.querySelector('.m-slider__tabs')) {
                        tabSwiper.el.querySelector('.m-slider__tab.a-link--on').classList.remove('a-link--on')
                        tabSwiper.el.querySelector(`.m-slider__tab[data-slide="${this.realIndex}"]`).classList.add('a-link--on')
                    }
                }
            }
        })
        contentSwiper.init()

        // Tab slider
        const tabSwiper = new Swiper(slider.querySelector('.m-slider__tabs'), {
            speed: 600,
            slidesPerView: 'auto',
            grabCursor: true,
            allowTouchMove: false,
            centeredSlides: true
        })
        tabSwiper.on('tap', function(event) {
            if(!event.target.classList.contains('swiper-wrapper') && !event.target.classList.contains('swiper-container')){
                contentSwiper.slideTo(tabSwiper.clickedSlide.getAttribute('data-slide'))
            }
        })
        contentSwiper.controller.control = tabSwiper
    }

    // Image sliders
    if(document.querySelector('.m-slider-image')) {
        new Swiper('.m-slider-image', {
            speed: 400,
            slidesPerView: 'auto',
            grabCursor: true,
            loop: true,
            navigation: {
                nextEl: '.m-slider-image__arrow'
            }
        })
    }

    // Fancybox
    jQuery(function() {
        jQuery('[data-fancybox]').fancybox({
            transitionEffect : 'slide',
            infobar: false,
            loop: true,
            buttons: [
                "close"
            ],
        })
    })

    // Form handle
    const forms = document.querySelectorAll('.m-form')
    for (let i = 0; i < forms.length; i++) {
        new Formino(forms[i])
    }

    // Subscribe form handle
    const subscribeForm = document.querySelector('.m-form--subscribe')
    if(subscribeForm) {
        // Diner checkbox
        const checkboxDiner = subscribeForm.querySelector('input#edit-sdm-subscribe-repas')
        const checkboxAccomp = subscribeForm.querySelector('input#edit-sdm-subscribe-accom')
        if(checkboxDiner) {
            if(!checkboxDiner.checked) {
                checkboxAccomp.disabled = true
            }
            checkboxDiner.addEventListener('change', () => {
                if(checkboxDiner.checked) {
                    checkboxAccomp.disabled = false
                } else {
                    checkboxAccomp.disabled = true
                    checkboxAccomp.checked = false
                    subscribeForm.querySelector('#edit-sdm-subscribe-opt-cond').style.display = 'none'
                }
            })
            checkboxAccomp.addEventListener('change', () => {
                if(checkboxAccomp.checked) {
                    subscribeForm.querySelector('#edit-sdm-subscribe-opt-cond').style.display = 'block'
                } else {
                    subscribeForm.querySelector('#edit-sdm-subscribe-opt-cond').style.display = 'none'
                }
            })
        }

        // 2 Maximum checkbox
        const checkboxesActivites = subscribeForm.querySelectorAll('fieldset#edit-sdm-subscribe-acts input[type="checkbox"]')
        if(checkboxesActivites) {
            let countCheckboxes = 0
            for (let i = 0; i < checkboxesActivites.length; i++) {
                if(checkboxesActivites[i].checked) {
                    countCheckboxes++
                }
                checkboxesActivites[i].addEventListener('change', () => { 
                    if(checkboxesActivites[i].checked) {
                        countCheckboxes++
                    } else {
                        countCheckboxes--
                    }
                    if(countCheckboxes >= 2) {
                        desactivateCheckboxes()
                    } else {
                        activateCheckboxes()
                    }
                })
            }
    
            function activateCheckboxes() {
                for (let i = 0; i < checkboxesActivites.length; i++) {
                    checkboxesActivites[i].disabled = false
                }
            }
            function desactivateCheckboxes() {
                for (let i = 0; i < checkboxesActivites.length; i++) {
                    if(!checkboxesActivites[i].checked) {
                        checkboxesActivites[i].disabled = true
                    }
                }
            }
        }
    }

    // Leaflet handle
    const mapContainer = document.querySelector('.m-map__gmap')
    if(mapContainer) {
        // Init map
        const map = L.map(mapContainer, {
            center: [mapContainer.dataset.lat, mapContainer.dataset.lng],
            zoom: 14,
            zoomControl: false
        })

        // Disable all mouse events
        map.dragging.disable()
        map.touchZoom.disable()
        map.doubleClickZoom.disable()
        map.scrollWheelZoom.disable()
        map.boxZoom.disable()
        map.keyboard.disable()

        // Tile layer
        L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png').addTo(map)

        // Controls
        L.control.zoom({ position: 'bottomright' }).addTo(map)

        // Marker
        const icon = L.icon({
            iconUrl: mapContainer.dataset.marker,
            iconSize:     [42, 42],
            iconAnchor:   [21, 42]
        })
        L.marker([mapContainer.dataset.lat, mapContainer.dataset.lng], {icon: icon}).addTo(map)
    }

    // Resize Handle
    let resizeTimer
    function onResize() {
        clearTimeout(resizeTimer)
        resizeTimer = setTimeout(function() {
            setMainHeight()
        }, 100)
    }
    window.addEventListener('resize', onResize);
})

function detectIE(win) {
    if (!win) {
        win = window;
    }
    var ua = win.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return 10;
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return 11;
    }

    // other browser
    return false;
}