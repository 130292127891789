export default class Formino {
    constructor(el) {
        if(el instanceof HTMLElement) {
            this.el = el
        } else {
            this.el = document.querySelector(el)
        }
        if(!this.el)
            return false
            
        this.inputs = this.el.querySelectorAll('input[type="text"], input[type="tel"], input[type="email"], input[type="password"]')

        // Init inputs
        for (let i = 0; i < this.inputs.length; i++) {
            // If not empty, add class
            setTimeout(() => { // setTimeout for drupal filling input via js
                if(!this.checkEmptyInput(this.inputs[i])) {
                    this.inputs[i].closest('.form-item').classList.add('form-item--filled')
                }
            }, 10);
            // Handle input change
            this.handleInputChange(this.inputs[i])
        }
    }

    handleInputChange(input) {
        input.addEventListener('input', () => {
            if(this.checkEmptyInput(input)) {
                input.closest('.form-item').classList.remove('form-item--filled')
            } else {
                input.closest('.form-item').classList.add('form-item--filled')
            }
        })
        input.addEventListener('focus', () => {
            if(this.checkEmptyInput(input)) {
                input.closest('.form-item').classList.add('form-item--filled')
            }
        })
        input.addEventListener('focusout', () => {
            if(this.checkEmptyInput(input)) {
                input.closest('.form-item').classList.remove('form-item--filled')
            }
        })
    }

    checkEmptyInput(input) {
        return input.value == '' ? true : false
    }
}


  